import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "preact/hooks";
import Cookies from "universal-cookie";

const AuthCheck = ({ children }) => {
	const {
		getAccessTokenSilently,
		isAuthenticated,
		isLoading,
		loginWithRedirect,
		error,
	} = useAuth0();

	const setCookie = (name, value, options) => {
		const cookies = new Cookies();
		cookies.set(name, value, options);
	};

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			loginWithRedirect();
		}
	}, [isLoading, isAuthenticated, loginWithRedirect]);

	useEffect(() => {
		(async () => {
			if (isAuthenticated) {
				const token = await getAccessTokenSilently();

				setCookie("x-access-token", token, {
					path: "/",
					secure: true,
					domain: "stuntbaboon.com",
					sameSite: "lax",
				});
			}
		})();
	}, [isAuthenticated]);

	if (isLoading) {
		return <h1>Loading...</h1>;
	}

	if (error) {
		throw error;
	}

	if (!isAuthenticated) {
		return null;
	}

	return <>{children}</>;
};

export { AuthCheck };
